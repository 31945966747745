import React from "react";

const FormSelect = ({ name, label, error, options, selected, ...rest }) => {
  !selected && (selected = "");

  return (
    <>
      <div className="form-group">
        <label htmlFor={name}>
          {label} <span className="error"> {error}</span>
        </label>
        <select name={name} id={name} {...rest} className="form-control">
          {selected === "" && options && options.length > 1 && (
            <option defaultValue>Choose...</option>
          )}
          {options &&
            options.map((option) => (
              <option key={option.id} value={option.id}>
                {option.name}
              </option>
            ))}
        </select>
      </div>
    </>
  );
};

export default FormSelect;
