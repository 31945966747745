import React from "react";
import Button from "../common/button";
import NestedRow from "./nested-row";

const TableRows = ({ ...props }) => {
  /**
   * Handle Rows
   * ------------------------------
   * Build rows with internal cols
   * @param {object} values
   */
  function handleRows({ values, option, handleOption, nested }) {
    if (values && values.length > 0) {
      return Object.keys(values).map((i) => (
        <React.Fragment key={i}>
          {nested ? (
            <NestedRow values={values} i={i} nested={nested} />
          ) : (
            <tr className="col" key={i}>
              {Object.keys(values[i]).map(function (value, k) {
                return value !== "id" && value !== "optionButton"
                  ? (values[i][value] === null ||
                      typeof values[i][value] !== "object") && (
                      <td key={k} tabIndex={k}>
                        {values[i][value]}
                      </td>
                    )
                  : handleOption && value === "optionButton" && (
                      <td key={k} tabIndex={k}>
                        <Button
                          name={option}
                          className="btn btn-outline-primary"
                          onClick={() => handleOption(values[i][value])}
                        />
                      </td>
                    );
              })}
            </tr>
          )}
        </React.Fragment>
      ));
    } else if (!values) {
      return (
        <tr className="no-results">
          <td>No results available</td>
        </tr>
      );
    }
  }

  return (
    <>
      <tbody>{handleRows({ ...props })}</tbody>
    </>
  );
};

export default TableRows;
