import React, { useState, useEffect } from "react";
import { sortValues, buildHeaders } from "../../services/utilities";
import ReactPaginate from "react-paginate";
import TableHeader from "./header";
import TableRows from "./rows";
import RowTitle from "./title";
import ButtonDropdown from "../common/button-dropdown";

const Table = ({
  source,
  title,
  header,
  option,
  handleOption,
  fileName,
  nested,
}) => {
  const [values, updateValues] = useState(source);
  const [paginated, updatePaginated] = useState([{}]);
  const [perPage, changePerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [offset, setOffset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    paginate(values);
  }, []);

  const paginate = (values, number = false) => {
    let pageNum = perPage;
    number && (pageNum = number);

    if (values) {
      changePerPage(pageNum);
      updatePaginated(values.slice(offset, offset + pageNum));
      setPageCount(Math.ceil(values.length / pageNum));
    }
  };

  const handlePageClick = (e) => {
    const selectedPage = e.selected;
    const offSet = selectedPage * perPage;
    const paginated = values.slice(offSet, offSet + perPage);

    setCurrentPage(selectedPage);
    setOffset(offset);
    updatePaginated(paginated);
  };

  const updatePage = (number) => {
    paginate(values, number);
  };

  const handleSort = (column, order) => {
    updateValues(sortValues(values, column, order));
    paginate(values);
  };

  return (
    <div className="container border-wrapper mb-3">
      <RowTitle
        title={title}
        fileName={fileName}
        data={values}
        headers={buildHeaders(header, values)}
      />
      <div className="table-wrapper">
        <table className="table table-hover table-borderd">
          {values ? (
            <>
              <TableHeader
                values={buildHeaders(header, values)}
                sort={handleSort}
              />
              <TableRows
                values={paginated}
                option={option}
                handleOption={handleOption}
                nested={nested}
              />
            </>
          ) : (
            <thead>
              <tr>
                <td>No results found in date range</td>
              </tr>
            </thead>
          )}
        </table>
      </div>
      <div className="table-footer">
        {perPage && (
          <ButtonDropdown
            name={perPage}
            options={[10, 25, 50, 100]}
            action={updatePage}
          />
        )}

        {values.length > pageCount ? (
          <ReactPaginate
            previousLabel={"<"}
            nextLabel={">"}
            breakLabel={"..."}
            breakClassName={"break-me"}
            pageCount={pageCount}
            marginPagesDisplayed={2}
            pageRangeDisplayed={3}
            onPageChange={handlePageClick}
            containerClassName={"pagination"}
            subContainerClassName={"pages pagination"}
            activeClassName={"active"}
          />
        ) : (
          <ul className="pagination ml-auto">
            <li className="active">
              <button className="btn one-page">1</button>
            </li>
          </ul>
        )}
      </div>
    </div>
  );
};

export default Table;
