import _ from "lodash";

/** -------------------------------------
 ** Set Headers
 * --------------------------------------
 * - Combines data keys with header values
 *    for correct react-csv format
 * ======================================
 * @param {array} data - array of objects
 * @param {array} headers
 * @returns {array} Array of objects
 */
export function setHeaders(data, headers, returnArray = false) {
  let modified = [{}],
    i = 0;
  headers = { ...headers };

  if (_.isEmpty(data)) {
    return [{ label: "Error occurred", key: "error" }];
  }

  Object.keys(data[0]).forEach((key) => {
    if (key !== "id") {
      if (returnArray) {
        typeof headers[i] !== "undefined" &&
          modified.push({ label: headers[i].name, key: key });
        i++;
      } else {
        modified.push({ label: headers[i], key: key });
        i++;
      }
    }
  });
  modified.shift();

  return modified;
}
