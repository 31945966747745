import React from "react";
import { CSVLink } from "react-csv";
import { setHeaders } from "../../services/export";

const RowTitle = ({ title, fileName, data, headers }) => {
  !data && (data = [{}]);
  return (
    <div className="row title">
      <div className="col">
        <h3>{title}</h3>
      </div>
      <div className="col">
        <CSVLink
          data={data}
          headers={setHeaders(data, headers, true)}
          filename={fileName}
          className="btn btn-outline-primary float-right"
        >
          Export CSV
        </CSVLink>
      </div>
    </div>
  );
};

export default RowTitle;
