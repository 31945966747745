import React, { useEffect, useContext } from "react";
import { UserContext } from "../context/context";
import { Link } from "react-router-dom";
import { updateTitle } from "../services/utilities";
import _ from "lodash";

const Home = () => {
  const { user } = useContext(UserContext);
  useEffect(() => {
    updateTitle();
  }, []);
  return (
    <>
      {!_.isEmpty(user) && user.username ? (
        <section className="center">
          <h1>Welcome {user.username}!</h1>
          <h4>This is the affiliate portal.</h4>
          <p>Here you can find details about your various accounts</p>
        </section>
      ) : (
        <section className="center">
          <h1>Welcome to the affiliate portal!</h1>
          <h4>
            <Link to="/login">Login</Link> to access your companies' account.
          </h4>
          <p>
            If you do not know your account information please contact your NRG
            representative.
          </p>
        </section>
      )}
    </>
  );
};

export default Home;
