import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { UserProvider, MessageProvider, AllProvider } from "./context/context";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
// Styles
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
import "./assets/font-awesome/css/all.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/sass/app.scss";

ReactDOM.render(
  <UserProvider>
    <MessageProvider>
      <AllProvider>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </AllProvider>
    </MessageProvider>
  </UserProvider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
