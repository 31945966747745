import React from "react";
import Joi from "joi-browser";
import Form from "../components/forms";
import _ from "lodash";
import { updateTitle, getVendorSession } from "../services/utilities";
import { createUser, deleteUser, editUser } from "../services/post";
import { getAllUsers, getAllLogs } from "../services/get";
import { UserContext } from "../context/context";
import Table from "../components/tables";
import Button from "../components/common/button";
import { toast } from "react-toastify";

class UserManagement extends Form {
  constructor() {
    super();
    this.state = {
      data: {
        username: "",
        email: "",
        affiliate: "",
        role: "",
      },
      header: ["Username", "Email", "Affiliate", "Role", "Edit"],
      nested: {
        values: {},
        header: ["Ip address", "Logged In", "Logged Out"],
        obj: "logs",
      },
      headerLogs: ["Username", "Affiliate", "Status", "Details"],
      showPopup: false,
      allowDelete: false,
      affiliates: [],
      roles: [],
      users: [],
      errors: {},
    };
    updateTitle();
  }
  static contextType = UserContext;

  schema = {
    username: Joi.string().required().label("Username"),
    email: Joi.string().required().email().label("Email"),
    affiliate: Joi.string().required().label("Affiliate ID"),
    role: Joi.string().required().label("Roles"),
    editAffiliate: Joi.string().allow("").label("Affiliate ID"),
    editRole: Joi.string().allow("").label("Role"),
  };

  // Create user required fields
  createData = {
    username: "",
    email: "",
    affiliate: "",
    role: "",
  };

  // Edit user required fields
  editData = {
    editAffiliate: "",
    editRole: "",
  };

  componentDidMount() {
    this.setRoles();
    this.initializeUsers();
    this.handleLogs();
  }

  async initializeUsers() {
    let { vendors } = this.props;
    const { user } = this.context;

    const users = await getAllUsers(user);

    // Set affiliate if only option
    if (vendors.length === 1) {
      const data = { ...this.state.data };
      data["affiliate"] = vendors[0].id;

      this.setState({
        affiliates: getVendorSession(vendors),
        users: users,
        data,
      });
    } else {
      this.setState({
        affiliates: getVendorSession(vendors),
        users: users,
      });
    }
  }

  setRoles = () => {
    const { user } = this.context;
    user.role === "admin" &&
      this.setState({
        roles: [
          { id: "admin", name: "Admin" },
          { id: "owner", name: "Owner" },
          { id: "agent", name: "Agent" },
        ],
      });

    user.role === "owner" &&
      this.setState({
        roles: [
          { id: "owner", name: "Owner" },
          { id: "agent", name: "Agent" },
        ],
      });
  };

  // Form actions ---------------------------

  handleEdit = async (id) => {
    const { users } = this.state;
    const data = { ...this.editData };
    let user = users.filter((value) => value.id === id);

    data.editAffiliate =
      user[0].affiliate !== "All Access" ? user[0].affiliate : "";

    this.setState({
      editID: id,
      editUser: user[0],
      showPopup: true,
      data,
    });
  };

  handleDelete = (e) => {
    e.preventDefault();
    this.setState({
      allowDelete: true,
    });
  };

  closePopup = () => {
    this.setState({
      editID: "",
      allowDelete: false,
      showPopup: false,
      role: this.createData,
    });
  };

  handleLogs = async () => {
    const logs = await getAllLogs();
    this.setState({ nested: { ...this.state.nested, values: logs } });
  };

  // Form submits ---------------------------

  submitEdit = async (e) => {
    try {
      e.preventDefault();
      const { editAffiliate, editRole } = this.state.data;
      const { editID } = this.state;
      const values = {
        id: editID,
        affiliate: editAffiliate,
        role: editRole,
      };
      const { data } = await editUser(values);

      // On Success
      if (data.success) {
        this.closePopup();
        toast.success(data.success);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  deleteUser = async () => {
    try {
      const { editID } = this.state;
      const { data } = await deleteUser({ id: editID });

      // On Success
      if (data.success) {
        this.closePopup();
        toast.success(data.success);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  doSubmit = async () => {
    try {
      const { username, email, affiliate, role } = this.state.data;
      const values = {
        username: username,
        email: email,
        affiliate: affiliate,
        role: role,
      };
      const { data } = await createUser(values);
      console.log(data);
      // On Success
      if (data.success) {
        toast.success(data.success);
        setTimeout(() => {
          window.location.reload();
        }, 3000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const {
      affiliates,
      users,
      header,
      showPopup,
      editUser,
      allowDelete,
      roles,
      headerLogs,
      nested,
    } = this.state;
    const { user } = this.context;

    return (
      <div className="container pt-4 mb-5">
        <h1>User Management</h1>
        <form onSubmit={this.handleSubmit}>
          {this.renderInput("username", "User name", "New User")}
          {this.renderInput(
            "email",
            "User email",
            "new.user@company.com",
            "email"
          )}
          {this.renderSelect("affiliate", "Affiliate", affiliates)}
          {this.renderSelect("role", "Role", roles)}
          {this.renderButton("Create new user")}
        </form>

        <div className="popup-container pt-4 mb-5">
          {!users ||
            (!_.isEmpty(users[0]) && (
              <>
                <Table
                  source={users}
                  header={header}
                  title="Users"
                  fileName="user-summary"
                  option="Edit"
                  handleOption={this.handleEdit}
                />

                <div className="popup-wrapper ">
                  <div className={"popup-form " + (showPopup ? "" : "hide")}>
                    <Button
                      className="btn close"
                      name=""
                      title="Close"
                      closeBtn={true}
                      onClick={this.closePopup}
                    />
                    <h3>Modify {editUser && editUser.username}</h3>
                    <form className="pt-4" onSubmit={this.submitEdit}>
                      {!_.isEmpty(user) && user.role === "admin" && (
                        <>
                          {this.renderSelect(
                            "editAffiliate",
                            "Affiliate",
                            affiliates,
                            editUser && editUser.affiliate
                          )}
                          {this.renderSelect(
                            "editRole",
                            "Role",
                            roles,
                            editUser && editUser.role
                          )}
                          {this.renderButton("Modify user", null, false)}
                        </>
                      )}
                      <Button
                        disabled={false}
                        className="btn btn-danger ml-2 delete-btn"
                        name="Delete user"
                        title="Delete user"
                        onClick={this.handleDelete}
                      />
                      {allowDelete && (
                        <Button
                          disabled={allowDelete ? false : true}
                          className="btn btn-warning ml-2"
                          name="Yes, delete user"
                          title="Close"
                          onClick={this.deleteUser}
                        />
                      )}
                    </form>
                  </div>
                </div>
              </>
            ))}

          {user.role === "admin" &&
            nested.values &&
            !_.isEmpty(nested.values) && (
              <Table
                source={nested.values}
                header={headerLogs}
                title="User logs"
                fileName="log-summary"
                handleOption={this.handleEdit}
                nested={nested}
              />
            )}
        </div>
      </div>
    );
  }
}

export default UserManagement;
