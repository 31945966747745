import React, { Component } from "react";
import { getWebEnrollments, getWebEnrollmentInfo } from "../services/get";
import {
  updateTitle,
  getVendorSession,
  buildHeaders,
} from "../services/utilities";
import _ from "lodash";
import Search from "../components/search";
import Table from "../components/tables";
import PopupTable from "../components/tables/popup";
import Loading from "../components/common/loading";

class Enrollments extends Component {
  constructor() {
    super();
    this.updateProps = this.updateProps.bind(this);
    this.state = {
      loading: false,
      values: [{}],
      displayPopup: false,
      selectedDate: new Date(),
      headerSummary: [
        "Created At",
        "Tracking ID",
        "Last Api Call",
        "Status",
        "Contract Account #",
        "Company Code",
        "Info",
      ],
      headerInfo: [
        "Created At",
        "Tracking ID",
        "Last Api Call",
        "Status",
        "Contract Account #",
        "Company Code",
      ],
      vendors: [],
      errors: [],
      vendor: {
        id: "",
      },
    };
    updateTitle();
  }

  componentDidMount() {
    // Get vendors if not set
    let { vendors } = this.props;
    this.setState({ vendors: getVendorSession(vendors) });
  }

  updateProps(props) {
    this.setState(props);
  }

  handleChange = (name) => {
    console.log(name);
    name !== "Choose..."
      ? this.setState({ vendor: { name }, errors: [] })
      : this.setState({ vendor: { name } });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let { name } = this.state.vendor;
    const { selectedDate, vendors } = this.state;

    if (name || vendors.length === 1) {
      this.setState({ loading: true });
      typeof name === "undefined" && (name = vendors[0].id);
      const values = await getWebEnrollments(name, selectedDate);
      if (values) {
        this.setState({
          loading: false,
          values: values,
          errors: [],
        });
      } else {
        console.log(values);
        this.setState({
          loading: false,
          values: false,
        });
      }
    } else {
      let select = "- Please make a selection";
      this.setState({ errors: { select: select } });
    }
  };

  handleInfo = async (id) => {
    console.log(id);
    const info = await getWebEnrollmentInfo(id);
    this.setState({ info: info, displayPopup: true });
  };

  handleClose = () => {
    this.setState({ displayPopup: false });
  };

  render() {
    const {
      loading,
      info,
      headerInfo,
      displayPopup,
      vendors,
      values,
      headerSummary,
      errors,
    } = this.state;

    return (
      <>
        <Search
          title="Web Enrollments"
          errors={errors}
          vendors={vendors}
          onChange={this.handleChange}
          onClick={this.handleSubmit}
          updateProps={this.updateProps}
        />

        {loading ? (
          <Loading />
        ) : (
          (!values || !_.isEmpty(values[0])) && (
            <>
              <Table
                source={values}
                header={headerSummary}
                title="Summary"
                fileName="web-enrollment-status"
                option="Info"
                handleOption={this.handleInfo}
              />
              <PopupTable
                show={displayPopup}
                title="Detail Logs for Enrollment"
                values={info}
                forExport={info}
                fileName="web-enrollment-info"
                header={buildHeaders(headerInfo, info)}
                close={this.handleClose}
              />
            </>
          )
        )}
      </>
    );
  }
}

export default Enrollments;
