import React, { useState } from "react";
import Button from "../common/button";
import NestedTable from "./nested";

const NestedRow = ({ values, i, nested }) => {
  const [show, toggleShow] = useState(false);

  return (
    <React.Fragment key={i}>
      <tr className="col" key={i}>
        {values[i] !== undefined &&
          Object.keys(values[i]).map(function (value, k) {
            return value !== "id" && value !== "optionButton"
              ? (values[i][value] === null ||
                  typeof values[i][value] !== "object") && (
                  <td key={k} tabIndex={k}>
                    {values[i][value]}
                  </td>
                )
              : value === "optionButton" && (
                  <td key={k} tabIndex={k}>
                    <Button
                      name="Info"
                      className="btn btn-outline-primary"
                      onClick={() => toggleShow(!show)}
                    />
                  </td>
                );
          })}
      </tr>
      {show && <NestedTable source={nested} index={i} />}
    </React.Fragment>
  );
};

export default NestedRow;
