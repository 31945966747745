import React, { Component } from "react";
import { getStatusEnrollments } from "../services/get";
import { updateTitle, getVendorSession } from "../services/utilities";
import _ from "lodash";
import Search from "../components/search";
import Table from "../components/tables";
import Loading from "../components/common/loading";

class Status extends Component {
  constructor() {
    super();
    this.updateProps = this.updateProps.bind(this);
    this.state = {
      loading: false,
      values: [{}],
      headerSummary: [
        "Creation Date",
        "Actual MVI",
        "Actual MVO",
        "Type",
        "Brand Name",
        "Status",
        "Sub-Status",
        "Trans Type",
        "ESI ID",
        // "Customer Name",
        // "Service Address",
        "Contract ID",
        "Contract Account Number",
        "ZZ Agreement Number",
        "Tracking ID / UCID",
        "Phone Number",
        "TDSP Type",
        "Promo Code",
        "Offer",
        "Length (Mo.)",
        "Days In Flow",
        "Cancel Date",
        "Completion Date",
        "Deposit Hold Date",
        "Deposit Release Date",
        "POSID Hold Date",
        "POSID Release Date",
        "Payable",
      ],
      selectedDate: new Date(),
      vendors: [],
      errors: [],
      vendor: {
        id: "",
      },
      payable: true,
      enrollment: "both",
    };
  }

  componentDidMount() {
    updateTitle();
    // Get vendors if not set
    let { vendors } = this.props;
    this.setState({ vendors: getVendorSession(vendors) });
  }

  updateProps(props) {
    this.setState(props);
  }

  handleChange = (name) => {
    name !== "Choose..."
      ? this.setState({ vendor: { name }, errors: [] })
      : this.setState({ vendor: { name } });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let { name } = this.state.vendor;
    const { selectedDate, enrollment, payable, vendors } = this.state;

    if (name || vendors.length === 1) {
      this.setState({ loading: true });
      typeof name === "undefined" && (name = vendors[0].id);
      const values = await getStatusEnrollments(
        name,
        selectedDate,
        enrollment,
        payable
      );
      if (values) {
        this.setState({
          loading: false,
          values: values,
          errors: [],
        });
      } else {
        this.setState({
          loading: false,
          values: false,
        });
      }
    } else {
      let select = "- Please make a selection";
      this.setState({ errors: { select: select } });
    }
  };

  render() {
    const { loading, vendors, values, headerSummary, errors } = this.state;

    return (
      <>
        <Search
          title="Enrollment Status"
          enrollments={true}
          payable={true}
          errors={errors}
          vendors={vendors}
          onChange={this.handleChange}
          onClick={this.handleSubmit}
          updateProps={this.updateProps}
        />
        {loading ? (
          <Loading />
        ) : (
          (!values || !_.isEmpty(values[0])) && (
            <Table
              source={values}
              header={headerSummary}
              title="Summary"
              fileName="enrollment-status"
            />
          )
        )}
      </>
    );
  }
}

export default Status;
