/* eslint-disable import/no-anonymous-default-export */

/**--------------------------------------
 ** Set session
 * --------------------------------------
 * @param {string} name
 * @param {mixed} session
 * @param {boolean} local use local storage
 * @returns {object}
 */
export function set(name, session, local = null) {
  try {
    if (typeof session === "object") {
      session = JSON.stringify(session);
    }
    return local
      ? localStorage.setItem(name, session)
      : sessionStorage.setItem(name, session);
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------------
 ** Get session values
 * --------------------------------------
 * @param {string} name
 * @param {boolean} local use local storage
 * @returns {object}
 */
export function get(name, local = null) {
  try {
    let session = local
      ? localStorage.getItem(name) || null
      : sessionStorage.getItem(name) || null;

    if (session !== null) {
      try {
        return JSON.parse(session);
      } catch (error) {
        return session;
      }
    }
    return session;
  } catch (error) {
    console.log(error);
  }
}

/**--------------------------------------
 ** Remove session
 * --------------------------------------
 * @param {string} name
 * @returns {object}
 */
export function remove(name, local = null) {
  try {
    return local
      ? localStorage.removeItem(name) || false
      : sessionStorage.removeItem(name) || false;
  } catch (error) {
    console.log(error);
  }
}

export default {
  set,
  get,
  remove,
};
