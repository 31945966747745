import http from "./http";
import _ from "lodash";
import auth from "./auth";
import { getJwt } from "./auth";
import { toast } from "react-toastify";
import session from "./session";

/**--------------------------------------
 ** Build GET Api string
 * --------------------------------------
 * @param {string} category
 * @returns {object}
 */
export async function getAPI(category = "") {
  return getJwt()
    .then(() => http.get(`get/${category}`))
    .catch((e) => {
      console.log(e);
      if (e.response.data.error) {
        const { error } = e.response.data;
        if (error.message === "Token is not valid!") window.location.reload();
        else {
          console.log(error.message);
          toast.error(error.message);
          return { data: { error: e } };
        }
      } else console.error(e);
      return { data: { error: e } };
    });
}

export async function getStatus() {
  const { data } = await getAPI("status/");
  !data.success && !data.error && console.log(data);
  return data;
}

export async function getAffiliates(affiliate) {
  const { data } = await getAPI("affiliates/");
  let values = "";

  if (typeof data.records === "undefined") {
    auth.isExpired(data);
    console.log(data);
    return "";
  }

  const affiliates = data.records.map((value) => {
    let values = {
      id: value.id,
      name: value.affiliate,
    };
    return values;
  });

  // Get logged in affiliate
  if (affiliate !== "" && affiliate !== null) {
    values = affiliates.filter((value) => {
      // Compare power sub groups
      if (value.id.startsWith(affiliate)) {
        return affiliate;
      }
    });
  } else {
    values = affiliates;
  }
  session.set("affiliates", values);
  return values;
}

export async function getAffiliateInfo(affiliate) {
  const search = `/info.php?a=${affiliate}`;
  const { data } = await getAPI("affiliates" + search);

  if (
    typeof data.info === "undefined" ||
    typeof data.contacts === "undefined" ||
    typeof data.callNumbers === "undefined"
  ) {
    auth.isExpired(data);
    // console.log(data);
    return "";
  }

  const info = {
    mainNum: data.info.mainNum,
    address: data.info.address,
    website: data.info.website,
  };

  const contacts = {
    primary: JSON.parse(data.contacts.primary),
    tech: JSON.parse(data.contacts.tech),
  };

  const callNumbers = Object.entries(data.callNumbers).map((value) => {
    let array = [];
    if (value[1].length > 1) {
      let current = value[1].length - 1;
      array = value[1].map((i, k) => {
        k !== current && (i += " | ");
        return i;
      });
    } else {
      array = value[1];
    }

    return {
      number: value[0],
      brand: array,
    };
  });

  return { info: info, contacts: contacts, callNumbers: callNumbers };
}

export async function getWhitelist(affiliate) {
  const search = `/whitelists.php?a=${affiliate}`;
  const { data } = await getAPI("affiliates" + search);

  if (typeof data.prod === "undefined" || typeof data.stage === "undefined") {
    auth.isExpired(data);
    // console.log(data);
    return false;
  }

  const prod = data.prod.map((value) => {
    let ips = JSON.parse(value.ipAddress);

    if (!_.isEmpty(ips)) {
      typeof ips !== "object" && (ips = [ips]);
    }

    var values = {
      id: value.id,
      url: value.url,
      ips: ips,
    };
    return values;
  });

  const stage = data.stage.map((value) => {
    let ips = JSON.parse(value.ipAddress);

    if (!_.isEmpty(ips)) {
      typeof ips !== "object" && (ips = [ips]);
    }

    var values = {
      id: value.id,
      url: value.url,
      ips: ips,
    };
    return values;
  });

  return { prod: prod, stage: stage };
}

export async function getCompensation(affiliate, dates, enrollment, locations) {
  dates = JSON.stringify(dates);
  const search = `/index.php?a=${affiliate}&d=${dates}&e=${enrollment}&l=${locations}`;
  const { data } = await getAPI("compensation" + search);

  if (
    typeof data.summary === "undefined" ||
    typeof data.records === "undefined"
  ) {
    auth.isExpired(data);
    console.log(data);
    return false;
  }

  // Keeps arrays in correct order for map
  const summary = data.summary.map((value) => {
    let values = {
      id: value.id,
      affiliate: value.affiliate,
      month: value.month,
      payable: value.payableCommissions,
    };
    return values;
  });

  const records = data.records.map((value) => {
    let values = {
      id: value.id,
      affiliate: value.affiliate,
      // actualMVI: value.actualMVI,
      createdDate: value.creationDate,
      trackingID: value.trackingID,
      type: value.type,
      phone: value.phone,
      brand: value.brand,
      offer: value.offer,
      length: value.length,
     // commission: value.commission !== null && "$" + value.commission + ".00",
      transType: value.transType,
      esiID: value.esiID,
      accountNum: value.accountNum,
      // address: value.address,
      // name: value.caName,
      tdsp: value.tdsp,
      promo: value.promo,
    };
    return values;
  });

  return { summary: summary, records: records };
}

export async function getStatusEnrollments(
  affiliate,
  dates,
  enrollment,
  payable
) {
  dates = JSON.stringify(dates);
  const search = `/status.php?a=${affiliate}&d=${dates}&e=${enrollment}&p=${payable}`;
  const { data } = await getAPI("enrollments" + search);

  if (typeof data.records === "undefined") {
    auth.isExpired(data);
    console.log(data);
    return false;
  }

  // Keeps arrays in correct order for map
  return data.records.map((value) => {
    let values = {
      id: value.id,
      createdDate: value.createdDate,
      actualMVI: value.actualMVI,
      actualMVO: value.actualMVO,
      type: value.type,
      brand: value.brand,
      status: value.status,
      subStatus: value.subStatus,
      transType: value.transType,
      esiID: value.esiID,
      // caName: value.caName,
      // address: value.address,
      accountID: value.accountID,
      accountNum: value.accountNum,
      zzNumber: value.zzNumber,
      trackingID: value.trackingID,
      phone: value.phone,
      tdsp: value.tdsp,
      promo: value.promo,
      offer: value.offer,
      length: value.length,
      days: value.days,
      cancelDate: value.cancelDate,
      completionDate: value.completionDate,
      depositHold: value.depositHold,
      depositRelease: value.depositRelease,
      posidHold: value.posidHold,
      posidRelease: value.posidRelease,
      payable: value.payable,
    };
    return values;
  });
}

export async function getWebEnrollments(affiliate, dates) {
  dates = JSON.stringify(dates);
  const search = `/web.php?a=${affiliate}&d=${dates}`;
  const { data } = await getAPI("enrollments" + search);

  if (typeof data.records === "undefined") {
    auth.isExpired(data);
    console.log(data);
    return false;
  }
  // Keeps arrays in correct order for map
  return data.records.map((value) => {
    let values = {
      id: value.id,
      createdDate: value.createdDate,
      trackingID: value.trackingID,
      apiCall: value.apiCall,
      status: value.status,
      accountNum: value.accountNum,
      companyCode: value.companyCode,
      optionButton: value.trackingID,
    };
    return values;
  });
}

export async function getWebEnrollmentInfo(trackingID) {
  const search = `/info.php?t=${trackingID}`;
  const { data } = await getAPI("enrollments" + search);

  if (typeof data.records === "undefined") {
    auth.isExpired(data);
    console.log(data);
    return false;
  }
  // Keeps arrays in correct order for map
  return data.records.map((value) => {
    let values = {
      id: value.id,
      createdDate: value.createdDate,
      trackingID: value.trackingID,
      apiCall: value.apiCall,
      status: value.status,
      accountNum: value.accountNum,
      companyCode: value.companyCode,
    };
    return values;
  });
}

export async function getPhoneActivity(affiliate, dates) {
  dates = JSON.stringify(dates);
  const search = `/index.php?a=${affiliate}&d=${dates}`;
  const { data } = await getAPI("phone-activity" + search);

  if (typeof data.records === "undefined") {
    auth.isExpired(data);
    console.log(data);
    return false;
  }
  // Keeps arrays in correct order for map
  const records = data.records.map((value) => {
    let values = {
      id: value.id,
      ucid: value.ucid,
      affiliate: value.affiliate,
      brand: value.brand,
      careNumber: value.careNumber,
      callTime: value.callTime,
      sale: value.saleTran,
    };
    return values;
  });

  let summary = [];
  let months = [];

  Object.values(data.summary).forEach((month, i) => {
    Object.values(month).forEach((value, i) => {
      let values = {
        id: i,
        month: value.month,
        brand: value.brand,
        careNumber: value.number,
        calls: value.calls,
        rate: value.rate,
      };
      months.push(values);
    });
    summary = months;
  });

  return { records: records, summary: summary };
}

export async function getAllUsers(user) {
  const { token } = user;

  const search = `/index.php?a=${token}`;
  const { data } = await getAPI("users" + search);

  if (typeof data.records === "undefined") {
    auth.isExpired(data);
    console.log(data);
    return "";
  }

  const users = data.records.map((value) => {
    let values = {
      id: value.id,
      username: value.username,
      email: value.email,
      affiliate: value.affiliate === null ? "All Access" : value.affiliate,
      role: value.role,
      optionButton: value.id,
    };
    return values;
  });

  return users;
}

export async function getAllLogs() {
  const { data } = await getAPI("users/logs.php");

  if (typeof data.success === "undefined") {
    auth.isExpired(data);
    console.log(data);
    return "";
  }

  const { success } = data;

  // Return empty message
  if (success.message) {
    return success.message;
  }

  var logs = [];
  Object.entries(success.logs).forEach(([key, value]) => {
    const logsList = value.map((value) => {
      let values = {
        id: value.id,
        ipAddress: value.ip_address,
        loggedIn: value.loggedIn,
        loggedOut: value.loggedOut,
      };
      return values;
    });

    if (logsList.length > 0) {
      var status = logsList[0].loggedOut;
      status.length > 10 && (status = "Logged Out - " + status);
    }

    logs.push({
      id: key,
      username: key,
      affiliate:
        success.logs[key][0].affiliate === null
          ? "NA"
          : success.logs[key][0].affiliate,
      logs: logsList,
      status: status,
      optionButton: key,
    });
  });

  return logs;
}

export async function getLockoutStatus() {
  const { data } = await getAPI("users/lockout.php");
  !data.success && !data.error && console.error(data);
  return data;
}
