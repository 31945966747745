import React from "react";
import { Redirect } from "react-router-dom";

/**
 * Lock down routes based on supplied value
 *
 * @param {boolean} auth check if authorized
 * @param {boolean} redirect if true redirect to login else hide routes
 */
export default function UserRoutes({ auth = true, children }) {
  if (!auth) {
    return <Redirect to="/login" />;
  }
  return children;
}
