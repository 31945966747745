import React, { useState, useEffect } from "react";
import { sortValues, buildHeaders } from "../../services/utilities";
import TableHeader from "./header";
import TableRows from "./rows";
import RowTitle from "./title";
import Button from "../common/button";

const PopupTable = ({
  source,
  title,
  fileName,
  header,
  handleInfo,
  show,
  close,
}) => {
  const [values, updateValues] = useState(source);
  const [paginated, updatePaginated] = useState([{}]);
  const [perPage, changePerPage] = useState(10);
  const [offset] = useState(0);
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    paginate(values);
  }, []);

  const paginate = (values, number = false) => {
    let pageNum = perPage;
    number && (pageNum = number);

    if (values) {
      changePerPage(pageNum);
      updatePaginated(values.slice(offset, offset + pageNum));
      setPageCount(Math.ceil(values.length / pageNum));
    }
  };

  const handleSort = (column, order) => {
    updateValues(sortValues(values, column, order));
    paginate(values);
  };

  return (
    <div className={show ? "info-wrapper" : "info-wrapper hide"}>
      <div className="container border-wrapper mb-3 popup-table">
        <RowTitle
          title={title}
          fileName={fileName}
          data={values}
          headers={buildHeaders(header, values)}
        />
        <div className="table-wrapper">
          <table className="table table-hover table-borderd">
            <TableHeader
              values={buildHeaders(header, values)}
              sort={handleSort}
            />
            <TableRows values={paginated} handleInfo={handleInfo} />
          </table>
        </div>
        <Button
          className="btn btn-info float-right"
          name="Close"
          onClick={close}
        />
      </div>
    </div>
  );
};

export default PopupTable;
