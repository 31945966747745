import React, { Component } from "react";
import Joi from "joi-browser";
import FormInput from "./input";
import FormSelect from "./select";
import FormCheck from "./checkbox";

class Form extends Component {
  state = {
    data: {},
    errors: {},
  };

  validate = () => {
    const options = { abortEarly: false };
    const { error } = Joi.validate(this.state.data, this.schema, options);
    if (!error) return null;

    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  };

  validateProperty = ({ name, value }) => {
    const obj = { [name]: value };
    const schema = { [name]: this.schema[name] };
    const { error } = Joi.validate(obj, schema);
    return error ? error.details[0].message : null;
  };

  handleSubmit = (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    this.doSubmit();
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    this.setState({ data, errors });
  };

  handleChecked = ({ currentTarget: checked }) => {
    const data = { ...this.state.data };
    let value;
    data[checked.name] ? (value = false) : (value = true);
    data[checked.name] = value;
    this.setState({ data });
  };

  renderButton(label, className = "btn-primary ", disabled = true, ...rest) {
    className === null && (className = "btn-primary ");
    return (
      <button
        disabled={disabled ? this.validate() : false}
        className={"btn " + className}
        {...rest}
      >
        {label}
      </button>
    );
  }

  renderSelect(name, label, options, selected = false) {
    const { data, errors } = this.state;
    let value = "";
    data[name] !== null && (value = data[name]);
    return (
      <FormSelect
        name={name}
        value={value}
        label={label}
        options={options}
        selected={selected}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderInput(name, label, placeholder, type = "text") {
    const { data, errors } = this.state;

    return (
      <FormInput
        type={type}
        name={name}
        placeholder={placeholder}
        value={data[name]}
        label={label}
        onChange={this.handleChange}
        error={errors[name]}
      />
    );
  }

  renderCheckbox(name, label, inline = false) {
    const { data, errors } = this.state;

    return (
      <FormCheck
        inline={inline}
        name={name}
        id={name}
        checked={data[name]}
        label={label}
        onChange={this.handleChecked}
        error={errors[name]}
      />
    );
  }
}

export default Form;
