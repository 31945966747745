import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { updateTitle } from "../services/utilities";

const NotFound = () => {
  useEffect(() => {
    updateTitle();
  }, []);
  return (
    <div className="container-fluid">
      <div className="container pt-3 mb-5 not-found">
        <h1>404</h1>
        <p>Page not found</p>
        <Link to="/">Return home</Link>
      </div>
    </div>
  );
};

export default NotFound;
