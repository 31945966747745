import React from "react";

const FormRadio = ({ name, id, label, ...rest }) => {
  return (
    <>
      <div className="form-check form-check-inline">
        <input
          {...rest}
          name={name}
          id={id}
          type="radio"
          className="form-check-input"
        />
        {label && <label htmlFor={id}>{label}</label>}
      </div>
    </>
  );
};

export default FormRadio;
