import React from "react";
import Dropdown from "react-bootstrap/Dropdown";

const ButtonDropdown = ({ name, id, options, action }) => {
  return (
    <Dropdown>
      <Dropdown.Toggle variant="secondary" id={id}>
        {name}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {options.map((i, k) => (
          <Dropdown.Item onClick={() => action(i)} key={k}>
            {i}
          </Dropdown.Item>
        ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ButtonDropdown;
