import React, { Component } from "react";
import { getCompensation } from "../services/get";
import { updateTitle, getVendorSession } from "../services/utilities";
import _ from "lodash";
import Search from "../components/search";
import Table from "../components/tables";
import Loading from "../components/common/loading";

class Compensation extends Component {
	constructor() {
		super();
		this.updateProps = this.updateProps.bind(this);
		this.state = {
			loading: false,
			values: [{}],
			headerSummary: ["Affiliate", "Month", "Number of Sales"],
			headerDetails: [
				"Affiliate",
				"Created Date",
				"Tracking ID",
				"Type",
				"Phone Number",
				"Brand Name",
				"Offer",
				"Length (Mo.)",
				//"Commission",
				"Trans Type",
				"ESI ID",
				"Contract Account Number",
				// "Service Address",
				// "Customer Name",
				"TDSP Type",
				"Promo Code",
			],
			selectedDate: new Date(),
			enrollment: "both",
      location:"all",
			vendors: [],
			errors: [],
			vendor: {
				id: "",
			},
		};
		updateTitle();
	}

	componentDidMount() {
		// Get vendors if not set
		let { vendors } = this.props;
		this.setState({ vendors: getVendorSession(vendors) });
	}

	updateProps(props) {
		this.setState(props);
	}

	handleChange = (name) => {
		name !== "Choose..."
			? this.setState({ vendor: { name }, errors: [] })
			: this.setState({ vendor: { name } });
	};

	handleSubmit = async (e) => {
		e.preventDefault();
		let { name } = this.state.vendor;
		const { selectedDate, enrollment, vendors, location } = this.state;
		if (name || vendors.length === 1) {
			this.setState({ loading: true });
			typeof name === "undefined" && (name = vendors[0].id);
			const values = await getCompensation(name, selectedDate, enrollment, location);

			if (values) {
				this.setState({
					loading: false,
					values: values,
					records: values.records,
					summary: values.summary,
					errors: [],
				});
			} else {
				this.setState({
					loading: false,
					values: false,
					records: false,
					summary: false,
				});
			}
		} else {
			let select = "- Please make a selection";
			this.setState({ errors: { select: select } });
		}
	};

	render() {
		const {
			loading,
			vendors,
			summary,
			records,
			values,
			headerSummary,
			headerDetails,
			errors,
		} = this.state;
		return (
			<>
				<Search
					title='View Sales Report'
					enrollments={false}
          locations={true}
					errors={errors}
					vendors={vendors}
					onChange={this.handleChange}
					onClick={this.handleSubmit}
					updateProps={this.updateProps}
				/>
				{loading ? (
					<Loading />
				) : (
					(!values || !_.isEmpty(values.records)) && (
						<>
							<Table
								source={summary}
								header={headerSummary}
								title='Summary'
								fileName='compensation-summary'
							/>
							<Table
								source={records}
								header={headerDetails}
								title='Details'
								fileName='compensation-details'
							/>
						</>
					)
				)}
			</>
		);
	}
}

export default Compensation;
