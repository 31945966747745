import React from "react";

const FormInput = ({
  name,
  error,
  label = false,
  readOnly = false,
  ...rest
}) => {
  return (
    <>
      <div className="form-group">
        {label && (
          <label htmlFor={name}>
            {label} <span>{error && error}</span>
          </label>
        )}
        <input
          {...rest}
          name={name}
          id={name}
          className="form-control"
          readOnly={readOnly}
        />
      </div>
    </>
  );
};

export default FormInput;
