import React, { useState, useEffect } from "react";
import { buildHeaders } from "../../services/utilities";
import TableHeader from "./header";
import TableRows from "./rows";
import _ from "lodash";

const NestTable = ({ source, index }) => {
  const [values, updateValues] = useState({});
  const [header, updateHeader] = useState({});

  useEffect(() => {
    getNested();
  }, []);

  const getNested = () => {
    let nestedValues = [];
    source.header && updateHeader(source.header);
    if (source.values) {
      nestedValues = Object.values(source.values).map((v) => v[source.obj]);
      updateValues(nestedValues);
    }
  };

  return (
    <tr className="nested-wrapper" key={"row-" + index}>
      <td>
        <div className="nested-table">
          <table className="">
            {values && !_.isEmpty(values) ? (
              <>
                <TableHeader values={buildHeaders(header, values)} />
                <TableRows values={values[index]} />
              </>
            ) : (
              <thead>
                <tr>
                  <td>No results found in date range</td>
                </tr>
              </thead>
            )}
          </table>
        </div>
      </td>
    </tr>
  );
};

export default NestTable;
