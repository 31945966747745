import React from "react";
import { getAffiliateInfo, getWhitelist } from "../services/get";
import { postWhitelistEditor, postContacts } from "../services/post";
import { AllContext } from "../context/context";
import session from "../services/session";
import {
  updateTitle,
  formatPhoneNum,
  getVendorSession,
} from "../services/utilities";
import _ from "lodash";
import Joi from "joi-browser";
import Form from "../components/forms";
import Accordion from "../components/common/accordion-list";

class Affiliates extends Form {
  constructor() {
    super();
    this.updateProps = this.updateProps.bind(this);
    this.state = {
      affiliates: [],
      data: {
        phoneNumber: "",
        address: "",
        website: "",
        addUrl: "",
        addEnv: "",
        addIP: "",
        contactType: "",
        contactUser: "",
        contactPhone: "",
        contactEmail: "",
      },
      errors: {},
    };
    updateTitle();
  }
  static contextType = AllContext;

  schema = {
    phoneNumber: Joi.string().allow(""),
    address: Joi.string().allow(""),
    website: Joi.string().allow(""),
    affiliate: Joi.string(),
    addUrl: Joi.string().allow(""),
    addEnv: Joi.string().allow(""),
    addIP: Joi.string().allow(""),
    contactType: Joi.string().allow(""),
    contactUser: Joi.string().allow(""),
    contactPhone: Joi.string().allow(""),
    contactEmail: Joi.string().allow(""),
  };

  componentDidMount() {
    const { user } = this.context;
    // updateTitle();
    // Get vendors if not set
    let { vendors } = this.props;
    const affiliates = getVendorSession(vendors);
    this.formatAffiliates(affiliates);
    this.setState({ affiliates: affiliates });
    user.affiliate !== "" && this.singleAffiliate(user);
  }

  updateProps = (props) => {
    this.setState({ props });
  };

  getAffiliateInfo = async (affiliate) => {
    const values = await getAffiliateInfo(affiliate);
    const { info, contacts, callNumbers } = values;
    const data = { ...this.state.data };

    data.affiliate = affiliate;
    if (!_.isEmpty(info)) {
      info.mainNum !== null && (data.phoneNumber = info.mainNum);
      info.address !== null && (data.address = info.address);
      info.website !== null && (data.website = info.website);
    }

    this.setState({ callNumbers: callNumbers, contacts: contacts, data });
  };

  getWhitelist = async (affiliate) => {
    const values = await getWhitelist(affiliate);
    this.setState({ whitelists: values });
  };

  singleAffiliate = (user) => {
    // Get affiliate info on change
    this.getAffiliateInfo(user.affiliate);
    this.getWhitelist(user.affiliate);
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    // Get affiliate info on change
    if (input.name === "affiliate") {
      this.getAffiliateInfo(input.value);
      this.getWhitelist(input.value);
    }

    const data = { ...this.state.data };
    data[input.name] = input.value;

    // Get affiliate info on change
    if (input.getAttribute("type") === "phone") {
      let formatted = formatPhoneNum(input.value);
      data[input.name] = formatted;
    }

    this.setState({ data, errors });
  };

  // Format affiliates to include IDs in name
  formatAffiliates = (affiliates) => {
    affiliates = affiliates.map((vendor) => {
      vendor.name = vendor.name + " - " + vendor.id;
      return vendor;
    });
    this.setState({ affiliates: affiliates });
  };

  // --------------------------------
  // Form submits
  // --------------------------------

  handleWhitelistSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    this.setState({ errors: errors || {} });
    if (errors) return;

    try {
      const { affiliate, addUrl, addEnv, addIP } = this.state.data;
      const { setMessage } = this.context;
      const update = {
        affiliate: affiliate,
        url: addUrl,
        env: addEnv,
        ipAddress: addIP,
      };
      const message = await postWhitelistEditor(update);
      console.log(message.data);
      if (message.data.success) {
        session.set("message", message.data);
        setMessage(message.data);
        this.setState({
          data: {
            ...this.state.data,
            addIP: "",
          },
        });
        this.getWhitelist(affiliate);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  handleContactSubmit = async (e) => {
    e.preventDefault();

    const errors = this.validate();
    const { setMessage } = this.context;
    this.setState({ errors: errors || {} });
    if (errors) return;

    try {
      const {
        affiliate,
        contactType,
        contactUser,
        contactPhone,
        contactEmail,
      } = this.state.data;

      const update = {
        affiliate: affiliate,
        type: contactType,
        user: contactUser,
        phone: contactPhone,
        email: contactEmail,
      };
      const message = await postContacts(update);
      console.log(message.data);

      if (message.data.success) {
        session.set("message", message.data);
        setMessage(message.data);
        this.setState({
          data: {
            ...this.state.data,
            contactType: "",
            contactUser: "",
            contactPhone: "",
            contactEmail: "",
          },
        });
        this.getAffiliateInfo(affiliate);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  doSubmit = async () => {
    try {
      const { affiliate, address, phoneNumber, website } = this.state.data;
      const { setMessage } = this.context;

      const submit = {
        affiliate: affiliate,
        address: address,
        phone: phoneNumber,
        website: website,
      };

      const message = await postContacts(submit);
      console.log(message.data);

      if (message.data.success) {
        session.set("message", message.data);
        setMessage(message.data);
        this.setState({
          data: {
            ...this.state.data,
            address: "",
            phoneNumber: "",
            website: "",
          },
        });
        this.getAffiliateInfo(affiliate);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    const { data, affiliates, contacts, whitelists, callNumbers } = this.state;
    const { user } = this.context;

    return (
      <div className="container pt-4 mb-5">
        <h1>Manage Affiliates</h1>
        <div className="flex">
          <div
            // className={!_.isEmpty(contacts) ? "container-left" : "container"}
            className="container"
          >
            <form className="mb-3" onSubmit={this.handleSubmit}>
              {this.renderSelect("affiliate", "Affiliate", affiliates)}
              {this.renderInput(
                "phoneNumber",
                "Contact Phone Number",
                "123-456-7897",
                "phone"
              )}
              {this.renderInput(
                "address",
                "Address",
                "123 Someplace Ln, Somewhere State, 77544"
              )}
              {this.renderInput("website", "Website URL", "somesite.com")}
              {this.renderButton("Save", "btn-primary btn-block")}
            </form>
          </div>

          {/* DISABLED
          {!_.isEmpty(contacts) && (
            <div className="container-right">
              <>
                <h5>{contacts[0].id}</h5>
                <p className="small">Phone number</p>
                <div className="h6">{contacts[0].mainNum}</div>
                <p className="small">Address</p>
                <div className="h6">{contacts[0].address}</div>
                <p className="small">Website</p>
                <div className="h6">{contacts[0].website}</div>
              </>
            </div>
          )} */}
        </div>
        {(_.isEmpty(data.affiliate) || data.affiliate !== "Choose...") && (
          <>
            <div className="row mt-3 break">
              <div className="col-6">
                <h4>Primary Contact</h4>
                {!_.isEmpty(contacts) && !_.isEmpty(contacts.primary) && (
                  <ul className="list-group mt-3">
                    <li className="list-group-item">
                      user: {contacts.primary.user}
                    </li>
                    <li className="list-group-item">
                      email: {contacts.primary.email}
                    </li>
                    <li className="list-group-item">
                      phone: {contacts.primary.phone}
                    </li>
                  </ul>
                )}
              </div>
              <div className="col-6">
                <h4>Technical Contact</h4>
                {!_.isEmpty(contacts) && !_.isEmpty(contacts.tech) && (
                  <ul className="list-group mt-3">
                    <li className="list-group-item">
                      user: {contacts.tech.user}
                    </li>
                    <li className="list-group-item">
                      email: {contacts.tech.email}
                    </li>
                    <li className="list-group-item">
                      phone: {contacts.tech.phone}
                    </li>
                  </ul>
                )}
              </div>
            </div>
            {!_.isEmpty(data.affiliate) && user.role === "admin" && (
              <div className="row mt-3 admin">
                <div className="col">
                  <h4>Contact Editor</h4>
                  <form onSubmit={this.handleContactSubmit}>
                    <div className="container">
                      {this.renderSelect("contactType", "Select type", [
                        { id: "primary", name: "Primary contact" },
                        { id: "tech", name: "Technical contact" },
                      ])}
                      {this.renderInput("contactUser", "Username", "SomeUser")}
                      {this.renderInput(
                        "contactEmail",
                        "Email address",
                        "some@email.com"
                      )}
                      {this.renderInput(
                        "contactPhone",
                        "Phone number",
                        "123-456-7897",
                        "phone"
                      )}
                    </div>
                    {this.renderButton(
                      "Update Contact",
                      "btn-success float-right"
                    )}
                  </form>
                </div>
              </div>
            )}

            <div className="row mt-3 break">
              <div className="col">
                <h4>Customer Call Numbers</h4>
              </div>
            </div>

            <div className="row my-3">
              <div className="col">
                <ul className="list-group">
                  {!_.isEmpty(callNumbers) &&
                    callNumbers.map((customer, k) => (
                      <li className="list-group-item" key={k}>
                        <h6>{customer.brand}</h6>
                        <h6>{customer.number}</h6>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
            {/* DISABLED
            {!_.isEmpty(data.affiliate) && user.admin && (
              <div className="row mt-3 admin">
                <div className="col">
                  <h4>Whitelist Editor</h4>
                  <form onSubmit={this.handleWhitelistSubmit}>
                    <div className="container">
                      {this.renderInput(
                        "addUrl",
                        "Add to Production Whitelist",
                        "/some/path/here.html"
                      )}
                      {this.renderSelect("addEnv", "Select environment", [
                        { id: "prod", name: "Production" },
                        { id: "stage", name: "Stage" },
                      ])}
                      {this.renderInput(
                        "addIP",
                        "Add IP address to whitelist",
                        "10.10.10.10"
                      )}
                    </div>
                    {this.renderButton(
                      "Save to lists",
                      "btn-success float-right"
                    )}
                  </form>
                </div>
              </div>
            )} */}

            <div className="row mt-3 break">
              <div className="col">
                <h4>
                  Whitelisted IP Addresses{" "}
                  <span className="badge badge-success">Production</span>
                </h4>
                {!_.isEmpty(whitelists) &&
                  !_.isEmpty(whitelists.prod) &&
                  whitelists.prod.map((list, key) => (
                    <Accordion list={list} key={key} />
                  ))}
              </div>
            </div>

            <div className="row mt-3 break">
              <div className="col">
                <h4>
                  Whitelisted IP Addresses{" "}
                  <span className="badge badge-primary">Stage</span>
                </h4>
                {!_.isEmpty(whitelists) &&
                  !_.isEmpty(whitelists.stage) &&
                  whitelists.stage.map((list, key) => (
                    <Accordion list={list} key={key} />
                  ))}
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default Affiliates;
