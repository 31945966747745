import http from "./http";
import { getJwt } from "./auth";
import { postify } from "./utilities";
import { toast } from "react-toastify";
import session from "./session";

/**--------------------------------------
 ** Build POST string
 * --------------------------------------
 * @param {string} category
 * @param {string} data
 */
export async function postAPI(path, data = { data: "empty" }) {
  return getJwt()
    .then(() => http.post(`post/${path}`, postify(data)))
    .catch((e) => {
      if (e.response.data.reset) {
        const { error } = e.response.data;
        if (error.reset) {
          session.remove("token");
          window.location.reload();
        } else {
          console.log(error.message);
          toast.error(error.message);
          return { data: { error: e } };
        }
      } else console.error(e);
      return { data: { error: e } };
    });
}

/**--------------------------------------
 ** POST Contacts
 * --------------------------------------
 * @param {object} values
 */
export async function postContacts(values) {
  const path = "/contacts.php";
  return await postAPI(`admin${path}`, values);
}

/**--------------------------------------
 *# POST Edit Whitelist (may be removed)
 * --------------------------------------
 * @param {object} values
 */
export async function postWhitelistEditor(values) {
  const path = "/whitelist.php";
  return await postAPI(`admin${path}`, values);
}

/**--------------------------------------
 ** POST Reset Password
 * --------------------------------------
 * @param {object} values
 */
export async function resetPass(values) {
  return await postAPI(`auth/reset.php`, values);
}

/**--------------------------------------
 ** POST Forgot Password
 * --------------------------------------
 * @param {object} values
 */
export async function forgotPassword(values) {
  return await postAPI(`auth/forgot.php`, values);
}

/**--------------------------------------
 ** POST Verify Password Reset
 * --------------------------------------
 * @param {object} values
 */
export async function verifyReset(values) {
  return await postAPI(`auth/verify.php`, values);
}

/**--------------------------------------
 ** POST Create User
 * --------------------------------------
 * @param {object} values
 */
export async function createUser(values) {
  return await postAPI(`users/create.php`, values);
}

/**--------------------------------------
 ** POST Edit Users
 * --------------------------------------
 * @param {object} values
 */
export async function editUser(values) {
  return await postAPI(`users/edit.php`, values);
}

/**--------------------------------------
 ** POST Delete Users
 * --------------------------------------
 * @param {object} values
 */
export async function deleteUser(values) {
  return await postAPI(`users/delete.php`, values);
}

/**--------------------------------------
 ** POST lockout entry
 * --------------------------------------
 * @param {object} values
 */
export async function setLockout(values) {
  return await postAPI(`auth/lockout.php`, values);
}
