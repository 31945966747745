import React, { useState } from "react";
import headerColumn from "./header-column";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";

export default ({ values, sort }) => {
  const icon = faSort;
  const [target, updateTarget] = useState(false);

  function resort(value, icon) {
    let order = "desc";
    if (icon.iconName === "sort-up") {
      icon = faSortDown;
    } else {
      icon = faSortUp;
      order = "asc";
    }
    updateTarget({ value: value, icon: icon });
    sort(value.var, order);
  }

  return (
    <>
      <thead>
        <tr>
          {values &&
            values.length &&
            values.map((value) => {
              if (!target) {
                return headerColumn(value, resort, icon);
              } else {
                return value.var === target.value.var
                  ? headerColumn(target.value, resort, target.icon)
                  : headerColumn(value, resort, icon);
              }
            })}
        </tr>
      </thead>
    </>
  );
};
