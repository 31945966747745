import _ from "lodash";
import session from "./session";

/** ---------------------------
 ** Update page title
 * ----------------------------
 * Gets location pathname
 *  cleans & formats it.
 * Appends site title to it.
 */
export function updateTitle() {
  try {
    const site = process.env.REACT_APP_TITLE;
    let page = window.location.pathname;
    page = page.split("/");
    page = page[page.length - 1];
    page = page.replace("/", "").replace("-", " ");
    document.title = capitalize(page) + " | " + site;
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Capitalize 1st letter of each word
 * ------------------------------------
 * @param string word or phrase
 * @return string formatted word or phrase
 */
export function capitalize(string) {
  try {
    const transform = (string) => {
      return string
        .toLowerCase()
        .split(" ")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
    };
    return transform(string);
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Convert object to POSTable values
 * ------------------------------------
 * @param object values
 * @return URLSearchParams POST format
 */
export function postify(values) {
  try {
    let params = new URLSearchParams();
    Object.entries(values).forEach((entry) => {
      const [key, value] = entry;
      params.append(key, value);
    });
    return params;
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Format phone number
 * ------------------------------------
 * @param n phone number
 * @return formatted number
 */
export function formatPhoneNum(n) {
  try {
    if (!n) return n;

    // only allows 0-9 inputs
    n = n.replace(/[^\d]/g, "");

    if (n.length < 4) return n;

    if (n.length < 7) return `${n.slice(0, 3)}-${n.slice(3)}`;

    return `${n.slice(0, 3)}-${n.slice(3, 6)}-${n.slice(6, 10)}`;
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Get and return url query
 * ------------------------------------
 * @return formatted query
 */
export function getQuery() {
  try {
    let search = window.location.search;

    // Remove ?
    search = search.substring(1);
    // Split values
    let values = search.split("&");
    var query = {};

    // Split values and keys
    if (values.length > 1) {
      query = [];

      // Set single pair to array of objects
      query.push(
        values.map((v) => {
          let value = v.split("=");
          let newValue = {};
          newValue[value[0]] = value[1];
          return newValue;
        })
      );
    } else {
      // Set single pair
      values = search.split("=");
      query[values[0]] = values[1];
    }
    return query;
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Filter query results
 * ------------------------------------
 * @param object query object
 * @param string filter
 * @return filtered value
 */
export function filterQuery(query, filter) {
  try {
    if (Object.prototype.toString.call(query) === "[object Array]") {
      const value = query[0].filter(
        (value) => typeof value[filter] !== undefined && value[filter]
      );
      return value[0][filter];
    } else {
      return query[filter];
    }
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Get affiliates from browser session
 * ------------------------------------
 * @param object vendors
 * @return object
 */
export function getVendorSession(vendors) {
  try {
    if (typeof vendors === "undefined") {
      return session.get("affiliates");
    } else {
      return vendors;
    }
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Decode encoded local session file
 * ------------------------------------
 * @param object user
 * @return object
 */
export function decodeUser(user) {
  let decode = {};
  Object.entries(JSON.parse(user)).forEach((v) => {
    decode[v[0]] = atob(v[1]);
  });
  return decode;
}

/** ------------------------------------
 ** Build table headers from arrays
 * ------------------------------------
 * @param array header
 * @param array values
 * @return object
 */
export function buildHeaders(header, values) {
  try {
    if (!_.isEmpty(values)) {
      let headerVars = [];
      let returnHeader = [];
      Object.entries(values[0]).forEach((value) => {
        if (value[0] !== "id") {
          headerVars.push(value[0]);
        }
      });
      header.forEach((value, i) => {
        returnHeader.push({ var: headerVars[i], name: value });
      });
      return returnHeader;
    }
  } catch (error) {
    console.log(error);
  }
}

/** ------------------------------------
 ** Sort table data
 * ------------------------------------
 * @param object values
 * @param string column
 * @param string order
 * @return object
 */
export function sortValues(values, column, order) {
  function compare(a, b) {
    const A = a[column];
    const B = b[column];

    let comparison = 0;
    if (A > B) {
      comparison = 1;
    } else if (A < B) {
      comparison = -1;
    }
    return order === "desc" ? comparison * -1 : comparison;
  }

  return values.sort(compare);
}

/** ------------------------------------
 ** Lockout user on too many attempts
 * ------------------------------------
 * @return object
 */
export function lockout() {
  let attempt = session.get("attempt", true);

  if (attempt) {
    if (attempt < 2) {
      attempt++;
      session.set("attempt", attempt, true);
      return false;
    } else {
      session.set("lockedOut", new Date(), true);
      session.remove("attempt", true);
      return true;
    }
  } else {
    session.set("attempt", 1, true);
    return false;
  }
}
