import React from "react";
import Joi from "joi-browser";
import Form from "../components/forms";
import { getJwt } from "../services/auth";
import { updateTitle } from "../services/utilities";
import { forgotPassword } from "../services/post";
import { UserContext } from "../context/context";
import { toast } from "react-toastify";

class Forgot extends Form {
  constructor() {
    super();
    this.state = {
      data: { username: "" },
      errors: {},
    };
    updateTitle();
  }
  // Get context
  static contextType = UserContext;

  schema = {
    username: Joi.string().required().email().label("Email"),
  };

  componentDidMount() {
    getJwt();
  }
  doSubmit = async () => {
    try {
      // Forgot password -------------------------------------
      const { username } = this.state.data;
      const values = {
        token: this.props.token,
        username: username,
      };

      const { data: status } = await forgotPassword(values);

      // On Success
      if (status.success) {
        toast.success(status.success);
        setTimeout(() => {
          window.location.href = "/login";
        }, 3000);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="container">
        <section className="login">
          <form onSubmit={this.handleSubmit}>
            {this.renderInput(
              "username",
              "Email address",
              "Enter your company email address",
              "email"
            )}
            {this.renderButton("Reset password")}
          </form>
        </section>
      </div>
    );
  }
}

export default Forgot;
