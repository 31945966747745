import React, { Component } from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { getAffiliates, getStatus } from "./services/get";
import { getVendorSession } from "./services/utilities";
import session from "./services/session";
import { UserContext } from "./context/context";
import timer from "./services/timer";
import _ from "lodash";
// Components
import { ToastContainer } from "react-toastify";
import Nav from "./components/nav";
import PrivateRoutes from "./components/common/private-routes";
import Alert from "./components/common/alerts";
// Pages
import Home from "./pages/home";
import Status from "./pages/enrollment-status";
import Compensation from "./pages/compensation";
import Enrollments from "./pages/web-enrollments";
import MyAccount from "./pages/my-account";
import Affiliates from "./pages/manage-affiliates";
import PhoneActivity from "./pages/phone-activity";
import NotFound from "./pages/not-found";
import Login from "./pages/login";
import Forgot from "./pages/forgot-pass";
import Logout from "./pages/logout";
import PasswordReset from "./pages/password-reset";
import UserManagement from "./pages/user-management";

class App extends Component {
  constructor() {
    super();
    this.state = {
      vendors: getVendorSession(),
    };
  }
  // Get context
  static contextType = UserContext;

  componentDidMount() {
    const { user } = this.context;
    if (!_.isEmpty(user)) {
      this.checkStatus();
      this.callAffiliates();
      timer();
    }
    this.setState({ loaded: true });
  }

  // Get affiliates from API
  callAffiliates = async () => {
    try {
      const { vendors } = this.state;
      const { user } = this.context;

      if (!_.isEmpty(user) && _.isEmpty(vendors)) {
        const affiliates = await getAffiliates(user.affiliate);
        session.set("affiliates", affiliates);
        this.setState({ vendors: affiliates });
      }
    } catch (error) {
      console.log(error);
    }
  };

  checkStatus = async () => {
    const { success, error } = await getStatus();
    if (success) {
      typeof success === "number" && success === 1
        ? session.set(
            "updating",
            "Systems are currently updating. Reports may show inaccurate data during this process. Try again in 10 minutes."
          )
        : session.remove("updating");
    }
    error && console.log(error);
  };

  render() {
    const { vendors, token } = this.state;
    const { user } = this.context;

    return (
      <>
        <ToastContainer autoClose={3000} />
        <Nav />
        <main>
          <Alert type="banner" />
          <Switch>
            <Route path="/home" render={() => <Home />} />
            <Route
              path="/password-reset"
              render={(props) => <PasswordReset {...props} prop={this.state} />}
            />
            <Route
              path="/login"
              render={() => (
                <Login updateLogin={this.updateLogin} token={token} />
              )}
            />
            <Route
              path="/forgot-pass"
              render={() => (
                <Forgot updateLogin={this.updateLogin} token={token} />
              )}
            />
            <Route path="/not-found" component={NotFound} />
            <Redirect from="/" exact to="/home" />
            <PrivateRoutes auth={!_.isEmpty(user)}>
              <Route
                path="/compensation"
                render={() => <Compensation vendors={vendors} />}
              />
              <Route
                path="/phone-activity"
                render={() => <PhoneActivity vendors={vendors} />}
              />
              <Route
                path="/web-enrollments"
                render={() => <Enrollments vendors={vendors} />}
              />
              <Route path="/my-account" render={() => <MyAccount />} />
              <Route
                path="/enrollment-status"
                render={() => <Status vendors={vendors} />}
              />
              <Route path="/logout" component={Logout} />

              {!_.isEmpty(user) &&
                (user.role === "admin" || user.role === "owner") && (
                  <>
                    <Route
                      path="/admin/affiliates"
                      render={() => <Affiliates prop={this.state} />}
                    />
                    <Route
                      path="/admin/user-management"
                      render={() => <UserManagement vendors={vendors} />}
                    />
                  </>
                )}
            </PrivateRoutes>
            <Redirect to="/not-found" />
          </Switch>
        </main>
      </>
    );
  }
}

export default App;
