import React from "react";
import Joi from "joi-browser";
import Form from "../components/forms";
import { getJwt } from "../services/auth";
import { updateTitle, lockout } from "../services/utilities";
import { UserContext } from "../context/context";
import session from "../services/session";
import auth from "../services/auth";
import { getLockoutStatus } from "../services/get";
import { setLockout } from "../services/post";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import loadingImg from "../assets/images/loading.gif";

class Login extends Form {
  constructor() {
    super();
    this.state = {
      data: { username: "", password: "" },
      forgotPass: false,
      lockout: false,
      loading: true,
      errors: {},
    };
    updateTitle();
  }
  // Get context
  static contextType = UserContext;

  schema = {
    username: Joi.string().required().email().label("Email"),
    password: Joi.string().required().min(8).label("Password"),
  };

  componentDidMount() {
    getJwt();
    this.lockedOut();
  }

  lockedOut = async () => {
    const { success, error } = await getLockoutStatus();
    if (success) {
      success.continue && this.setState({ lockout: false });
    }
    error && console.log(error.message);
    this.setState({ loading: false });
  };

  doSubmit = async () => {
    try {
      const { username, password } = this.state.data;
      const values = {
        token: this.props.token,
        username: username,
        password: password,
      };

      const { success, error } = await auth.login(values);
      // On Success
      if (success && success.token !== "undefined") {
        const { state } = this.props;
        session.set("token", success.token);
        session.remove("inactive"); // Clear banner
        let continueSession = session.get("continue");
        // Redirect to previous page
        if (continueSession) {
          let exp = new Date(continueSession.exp);
          const now = new Date();
          exp = exp.setHours(exp.getHours() + 3);
          // If not expired use previous link
          exp < now
            ? session.remove("continue")
            : (window.location = continueSession.url);
        } else window.location = state ? state.from.pathname : "/";
      } else {
        toast.error(error);

        if (lockout()) {
          this.setState({ lockout: true });
          const data = await setLockout();
          console.log(data);
          return;
        }
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="container">
        <section className="login">
          {this.state.loading ? (
            <div className="loading-wrapper">
              <img src={loadingImg} alt="loading" />
            </div>
          ) : !this.state.lockout ? (
            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "username",
                "Email address",
                "Enter your company email address",
                "email"
              )}
              {this.renderInput("password", "Password", "Password", "password")}
              <div>
                <Link to="/forgot-pass">Forgot your password?</Link>
              </div>
              {this.renderButton("Login")}
            </form>
          ) : (
            <>
              <h2>You have been locked out due to too many failed attempts</h2>
              <p>Try again in 30 minutes.</p>
              <div>
                <Link to="/forgot-pass">Forgot your password?</Link>
              </div>
            </>
          )}
        </section>
      </div>
    );
  }
}

export default Login;
