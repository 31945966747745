import React from "react";
import Joi from "joi-browser";
import Form from "../components/forms";
import { UserContext } from "../context/context";
import { updateTitle } from "../services/utilities";
import { resetPass } from "../services/post";
import { toast } from "react-toastify";

class MyAccount extends Form {
  constructor() {
    super();
    this.state = {
      data: { oldPass: "", password: "", passConfirm: "" },
      showConfirmPass: false,
      passMatch: false,
      errors: {},
    };
    updateTitle();
  }
  static contextType = UserContext;

  schema = {
    oldPass: Joi.string().required().label("Current password"),
    password: Joi.string()
      .min(8)
      .regex(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,50})/)
      .required()
      .label("New password")
      .error(() => ({
        message:
          "- must be at least 8 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 number & 1 special character",
      })),
    passConfirm: Joi.string().required().label("Confirm new password"),
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    // Error for previous password use
    if (input.name === "oldPass" && input.value !== "") {
      data.password !== "" &&
        data.password === input.value &&
        (errors["password"] = "- you cannot use the same previous password");
      // : delete errors["password"];
    }

    // Error for previous password use
    if (input.name === "password" && input.value !== "") {
      data.oldPass !== "" &&
        data.oldPass === input.value &&
        (errors["password"] = "- you cannot use the same previous password");
      // : delete errors["password"];

      // Display confirm password
      typeof errors.password === "undefined" &&
        this.setState({ showConfirmPass: true });
    }

    // Compare password & confirm
    if (input.name === "passConfirm") {
      if (data.password !== "") {
        const length = data.password.length,
          confirmLength = input.value.length;

        if (!data.password.startsWith(input.value) || confirmLength > length) {
          errors[input.name] = "- passwords do not match";
        }
      }
    }

    // Show save button
    data.oldPass !== "" &&
    data.passConfirm !== "" &&
    data.oldPass !== data.password &&
    data.password === data.passConfirm
      ? this.setState({ passMatch: true })
      : this.setState({ passMatch: false });

    // Set data and errors
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    try {
      const { oldPass, password } = this.state.data;
      const { user } = this.context;
      const values = {
        token: user.token,
        oldPass: oldPass,
        newPass: password,
      };

      const { data: status } = await resetPass(values);

      // On Success
      if (status.success) {
        this.setState({
          passMatch: false,
          showConfirmPass: false,
          data: {
            oldPass: "",
            password: "",
            passConfirm: "",
          },
        });
        toast.success(status.success);
      } else {
        console.log(status);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <div className="container-fluid">
        <div className="container pt-3 mb-5">
          <h1 className="p-3">Manage Account</h1>

          <div className="container border-wrapper p-2 mt-4">
            <div className="container-title">
              <h3>Change Password</h3>
            </div>
            <p>
              Please enter your old password, for security’s sake, and then
              enter your new password twice so we can verify you typed it in
              correctly.
            </p>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "oldPass",
                "Current password",
                "Enter your current password",
                "password"
              )}
              {this.renderInput(
                "password",
                "New password",
                "Enter your new password",
                "password"
              )}
              {this.state.showConfirmPass &&
                this.renderInput(
                  "passConfirm",
                  "Confirm password",
                  "Confirm your new password",
                  "password"
                )}
              {this.state.passMatch && this.renderButton("Change my password")}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default MyAccount;
