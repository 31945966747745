import React, { useState, useEffect, useContext } from "react";
import { NavLink } from "react-router-dom";
import { UserContext } from "../context/context";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import _ from "lodash";

const Navigation = () => {
  useWindowSize();
  const [toggle, updateToggle] = useState(false);
  const { user } = useContext(UserContext);

  /**
   * Handle Toggle Method
   * @param {bool} clear set toggle to false
   */
  function handleToggle(clear = false) {
    clear
      ? updateToggle(false)
      : updateToggle((prevState) => (prevState ? false : true));
  }

  function useWindowSize() {
    useEffect(() => {
      function handleResize() {
        if (window.innerWidth > 991) {
          updateToggle(handleToggle(true));
        }
      }

      window.addEventListener("resize", handleResize);
      handleResize();

      return () => window.removeEventListener("resize", handleResize);
    }, []);
  }

  return (
    <Navbar
      bg="dark"
      expand="lg"
      variant="dark"
      id="main-nav"
      expanded={toggle}
      onToggle={() => updateToggle(handleToggle)}
    >
      <div className="navbar-brand">
        <NavLink to="/">Affiliate Portal</NavLink>
      </div>
      <Navbar.Toggle aria-controls="navbar-nav" />
      <Navbar.Collapse id="navbar-nav">
        {!_.isEmpty(user) && (
          <Nav className="mr-auto ">
            <NavLink
              className="nav-item nav-link"
              to="/compensation"
              onClick={() => updateToggle(handleToggle(true))}
            >
              Sales Report
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/phone-activity"
              onClick={() => updateToggle(handleToggle(true))}
            >
              Phone # Activity
            </NavLink>
            <NavLink
              className="nav-item nav-link"
              to="/enrollment-status"
              onClick={() => updateToggle(handleToggle(true))}
            >
              Enrollment Status
            </NavLink>
            {/* {!_.isEmpty(user) && user.role === "admin" && (
              <NavLink
                className="nav-item nav-link"
                to="/web-enrollments"
                onClick={() => updateToggle(handleToggle(true))}
              >
                Web Enrollments
              </NavLink>
            )} */}
            {!_.isEmpty(user) &&
              (user.role === "admin" || user.role === "owner") && (
                <NavDropdown
                  id="adminDropdown"
                  title="Admin Tools"
                  className="end-menu"
                  rootCloseEvent="click"
                  renderMenuOnMount={true}
                >
                  <NavLink
                    className="nav-item nav-link"
                    to="/admin/affiliates"
                    onClick={() => updateToggle(handleToggle(true))}
                  >
                    Manage Affiliates
                  </NavLink>
                  <NavLink
                    className="nav-item nav-link"
                    to="/admin/user-management"
                    onClick={() => updateToggle(handleToggle(true))}
                  >
                    User Management
                  </NavLink>
                </NavDropdown>
              )}
          </Nav>
        )}
        <Nav className="ml-auto mr-2">
          {!_.isEmpty(user) ? (
            <>
              <NavDropdown
                title=""
                className="end-menu fas fa-user"
                renderMenuOnMount={true}
              >
                <NavLink
                  className="nav-item nav-link"
                  to="/my-account"
                  onClick={() => updateToggle(handleToggle(true))}
                >
                  My account
                </NavLink>
                <NavLink
                  className="nav-item nav-link"
                  to="/logout"
                  onClick={() => updateToggle(handleToggle(true))}
                >
                  Logout
                </NavLink>
              </NavDropdown>
            </>
          ) : (
            <NavLink className="nav-item nav-link" to="/login">
              Login
            </NavLink>
          )}
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default Navigation;
