import React, { Component } from "react";
import { getPhoneActivity } from "../services/get";
import { updateTitle, getVendorSession } from "../services/utilities";
import _ from "lodash";
import Search from "../components/search";
import Table from "../components/tables";
import Loading from "../components/common/loading";

class PhoneActivity extends Component {
  constructor() {
    super();
    this.updateProps = this.updateProps.bind(this);
    this.state = {
      loading: false,
      values: [{}],
      headerSummary: [
        "Month",
        "Brand Name",
        "Customer Care Number",
        "Calls Handled",
        "Conversion Rate",
      ],
      headerDetails: [
        "UCID",
        "Affiliate",
        "Brand Name",
        "Customer Care Number",
        "Call Time",
        "Sale Transaction",
      ],
      selectedDate: new Date(),
      vendors: [],
      errors: [],
      vendor: {
        id: "",
      },
    };
    updateTitle();
  }

  componentDidMount() {
    // Get vendors if not set
    let { vendors } = this.props;
    this.setState({ vendors: getVendorSession(vendors) });
  }

  updateProps(props) {
    this.setState(props);
  }

  handleChange = (name) => {
    name !== "Choose..."
      ? this.setState({ vendor: { name }, errors: [] })
      : this.setState({ vendor: { name } });
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    let { name } = this.state.vendor;
    const { selectedDate, vendors } = this.state;

    if (name || vendors.length === 1) {
      this.setState({ loading: true });
      typeof name === "undefined" && (name = vendors[0].id);
      const values = await getPhoneActivity(name, selectedDate);

      if (values) {
        this.setState({
          loading: false,
          values: values,
          records: values.records,
          summary: values.summary,
          errors: [],
        });
      } else {
        this.setState({
          loading: false,
          values: false,
          records: false,
          summary: false,
        });
      }
    } else {
      let select = "- Please make a selection";
      this.setState({ errors: { select: select } });
    }
  };

  render() {
    const {
      loading,
      vendors,
      summary,
      records,
      values,
      headerSummary,
      headerDetails,
      errors,
    } = this.state;

    return (
      <>
        <Search
          title="Phone Number Activity"
          errors={errors}
          vendors={vendors}
          onChange={this.handleChange}
          onClick={this.handleSubmit}
          updateProps={this.updateProps}
        />

        {loading ? (
          <Loading />
        ) : (
          (!values || !_.isEmpty(values.records)) && (
            <>
              <Table
                source={summary}
                header={headerSummary}
                title="Summary"
                fileName="phone-activity-summary"
              />
              <Table
                source={records}
                header={headerDetails}
                title="Details"
                fileName="phone-activity-details"
              />
            </>
          )
        )}
      </>
    );
  }
}

export default PhoneActivity;
