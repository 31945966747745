import React from "react";
import Joi from "joi-browser";
import Form from "../components/forms";
import { updateTitle, getQuery, filterQuery } from "../services/utilities";
import { resetPass, verifyReset } from "../services/post";
import { toast } from "react-toastify";

class PasswordReset extends Form {
  constructor() {
    super();
    this.state = {
      hide: false,
      data: { password: "", passConfirm: "" },
      showConfirmPass: false,
      passMatch: false,
      errors: {},
    };
    updateTitle();
  }

  schema = {
    password: Joi.string()
      .min(8)
      .regex(/((?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,50})/)
      .required()
      .label("New password")
      .error(() => ({
        message:
          "- must be at least 8 characters long and contain at least 1 lowercase letter, 1 uppercase letter, 1 number & 1 special character",
      })),
    passConfirm: Joi.string().required().label("Confirm new password"),
  };

  componentDidMount() {
    this.handleQuery();
  }

  handleQuery = async () => {
    const token = filterQuery(getQuery(), "token");

    if (typeof token !== "undefined") {
      const values = { token: token };

      try {
        const { data: status } = await verifyReset(values);

        // On Success
        if (status.success) {
          console.log(status);
          this.setState({ user: status.success });
        } else {
          this.setState({ hide: true });
          console.log(status);
        }
      } catch (ex) {
        if (ex.response && ex.response.status === 400) {
          const errors = { ...this.state.errors };
          errors.username = ex.response.data;
          this.setState({ errors });
        }
      }
    } else {
      this.setState({ hide: true });
    }
  };

  handleChange = ({ currentTarget: input }) => {
    const errors = { ...this.state.errors };
    const errorMessage = this.validateProperty(input);
    if (errorMessage) errors[input.name] = errorMessage;
    else delete errors[input.name];

    const data = { ...this.state.data };
    data[input.name] = input.value;

    if (input.name === "password" && input.value !== "") {
      // Display confirm password
      typeof errors.password === "undefined" &&
        this.setState({ showConfirmPass: true });
    }

    // Compare password & confirm
    if (input.name === "passConfirm") {
      if (data.password !== "") {
        const length = data.password.length,
          confirmLength = input.value.length;

        if (!data.password.startsWith(input.value) || confirmLength > length) {
          errors[input.name] = "- passwords do not match";
        }
      }
    }

    // Show save button
    data.passConfirm !== "" && data.password === data.passConfirm
      ? this.setState({ passMatch: true })
      : this.setState({ passMatch: false });

    // Set data and errors
    this.setState({ data, errors });
  };

  doSubmit = async () => {
    try {
      const { password } = this.state.data;
      const { id, token } = this.state.user;
      const values = {
        newPass: password,
        id: id,
        token: token,
      };

      const { data: status } = await resetPass(values, true);

      // On Success
      if (status.success) {
        console.log(status);
        toast.success(status.success);
        setTimeout(() => {
          window.location.replace("/login");
        }, 3000);
        this.setState({
          passMatch: false,
          showConfirmPass: false,
          data: {
            password: "",
            passConfirm: "",
          },
        });
      } else {
        console.log(status);
      }
    } catch (ex) {
      if (ex.response && ex.response.status === 400) {
        const errors = { ...this.state.errors };
        errors.username = ex.response.data;
        this.setState({ errors });
      }
    }
  };

  render() {
    return (
      <>
        <h1 className="p-3">Password Reset</h1>

        {!this.state.hide ? (
          <div className="container border-wrapper p-2 mt-4">
            <div className="container-title">
              <h3>Change Password</h3>
            </div>
            <p>
              Enter your new password twice so we can verify you typed it in
              correctly.
            </p>
            <form onSubmit={this.handleSubmit}>
              {this.renderInput(
                "password",
                "New password",
                "Enter your new password",
                "password"
              )}
              {this.state.showConfirmPass &&
                this.renderInput(
                  "passConfirm",
                  "Confirm password",
                  "Confirm your new password",
                  "password"
                )}
              {this.state.passMatch && this.renderButton("Change my password")}
            </form>
          </div>
        ) : (
          <div className="container border-wrapper p-2 mt-4">
            <div className="container-title">
              <h3>Invalid Token</h3>
            </div>
            <p>
              Something went wrong with your request. Please request another
              password reset to receive a new email.
            </p>
          </div>
        )}
      </>
    );
  }
}

export default PasswordReset;
