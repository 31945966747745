import React, { useState } from "react";

function Accordion({ list }) {
  const [hide, toggleHide] = useState(true);

  return (
    <>
      <ul className="list-group">
        <li
          className="list-group-item toggle-list"
          onClick={() => toggleHide((hide) => !hide)}
        >
          {list.url}
        </li>
        <ul className={`list-group mb-3 hidden-list-${hide}`}>
          {list.ips.map((ip, k) => (
            <li className="list-group-item" key={k}>
              {ip}
            </li>
          ))}
        </ul>
      </ul>
    </>
  );
}

export default Accordion;
