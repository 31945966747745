import React from "react";

const Button = ({ name, values = false, closeBtn = false, ...rest }) => {
  return (
    <button
      {...rest}
      id={values && values.length > 0 ? values[0].id : undefined}
    >
      {closeBtn ? <span>&#10005;</span> : name}
    </button>
  );
};

export default Button;
