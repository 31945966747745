import React, { useState, useContext, useEffect } from "react";
import { AllContext } from "../../context/context";
import session from "../../services/session";

export default ({ type }) => {
  let { message } = useContext(AllContext);
  const [show, toggleShow] = useState(false);
  const inactive = session.get("inactive") || null;
  const updating = session.get("updating") || null;

  useEffect(() => {
    let message = session.get("message");

    message && toggleShow(true);
    inactive && toggleShow(true);
    updating && toggleShow(true);
  }, [message, inactive, updating]);

  const wipeMessage = () => {
    message && session.remove("message");
    inactive && session.remove("inactive");
    toggleShow(false);
  };

  return (
    <>
      {!inactive && message && (
        <div className={`alert-wrapper ${type}`}>
          <div
            className={
              "alert-container" +
              (!show ? " hide" : "") +
              (message.success ? " success" : " errors")
            }
          >
            <button className="close" onClick={() => wipeMessage()}>
              &#10005;
            </button>
            {message.success && (
              <>
                <h2>Success</h2>
                <p>{message.success}</p>
              </>
            )}
            {message.errors && (
              <>
                <h2>Error</h2>
                <p>{message.errors}</p>
              </>
            )}
          </div>
        </div>
      )}
      {inactive && (
        <div className={`alert-wrapper inactive-banner`}>
          <div className={"alert-container warning" + (!show ? " hide" : "")}>
            <button className="close" onClick={() => wipeMessage()}>
              &#10005;
            </button>
            <p>{inactive}</p>
          </div>
        </div>
      )}
      {updating && (
        <div className={`alert-wrapper inactive-banner`}>
          <div className={"alert-container warning" + (!show ? " hide" : "")}>
            <button className="close" onClick={() => wipeMessage()}>
              &#10005;
            </button>
            <p>{updating}</p>
          </div>
        </div>
      )}
    </>
  );
};
