import React from "react";

const FormCheck = ({ name, id, label, inline = true, error, ...rest }) => {
  return (
    <>
      <div
        className={inline ? "form-check form-check-inline" : "form-check mb-3"}
      >
        <input
          {...rest}
          name={name}
          id={id}
          type="checkbox"
          className="form-check-input"
        />
        {label && (
          <label htmlFor={id}>
            {label} <span>{error && error}</span>
          </label>
        )}
      </div>
    </>
  );
};

export default FormCheck;
