import React, { Component } from "react";
import Button from "./common/button";
import FormInput from "./forms/input";
import FormSelect from "./forms/select";
import FormCheck from "./forms/checkbox";
import FormRadio from "./forms/radio";
import Calendar from "react-calendar";

class Search extends Component {
  state = {
    dateDisabled: true,
    showCalendar: false,
    startDate: "",
    endDate: "",
  };

  updateProps = (props) => {
    // Send to parent controller
    this.props.updateProps(props);
  };

  calendarChange = (date) => {
    if (date.length > 1) {
      this.handleDates(date);
    }
    this.setState({ showCalendar: false });
  };

  handleDates = (date) => {
    let newDates = date;
    newDates.forEach((time, i) => {
      const d = new Date(time),
        ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d),
        mo = new Intl.DateTimeFormat("en", { month: "numeric" }).format(d),
        da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);
      newDates[i] = `${mo}/${da}/${ye}`;
    });

    const dates = [newDates[0], newDates[1]];

    this.updateProps({ selectedDate: dates });

    this.setState({
      startDate: newDates[0],
      endDate: newDates[1],
    });
  };

  handleRadioClick = (e) => {};

  handleDisabled = (e) => {
    const id = e.currentTarget.id;

    id === "dateCustom"
      ? this.setState({ dateDisabled: false })
      : this.setState({ dateDisabled: true });
  };

  handleEnrollment = (e) => {
    const id = e.currentTarget.id;
    this.updateProps({ enrollment: id });
  };

  handleLocation = (e) => {
    const id = e.currentTarget.id;
    this.updateProps({ location: id });
  };

  handlePayable = (e) => {
    const checked = e.currentTarget.checked;
    this.updateProps({ payable: checked });
  };

  setDates = (e) => {
    const id = e.currentTarget.id;
    let dateRange = [];

    if (id === "dateThisMonth") {
      let firstOfMonth = new Date();
      // Beginning of month
      firstOfMonth.setDate(1);
      firstOfMonth.setHours(0, 0, 0, 0);
      // Current date
      let now = new Date();
      now.setHours(23, 59, 59, 59);
      // Set array
      dateRange = [firstOfMonth, now];
    } else if (id === "dateLastMonth") {
      let lastDayOfMonth = new Date();
      // Last day of last month
      lastDayOfMonth.setDate(1);
      lastDayOfMonth.setDate(0);
      lastDayOfMonth.setHours(23, 59, 59, 59);

      // First day of last month
      let lastMonth = new Date();
      lastMonth.setMonth(lastMonth.getMonth() - 1);
      lastMonth.setDate(1);
      lastMonth.setHours(0, 0, 0, 0);

      // Set array
      dateRange = [lastMonth, lastDayOfMonth];
    }

    this.updateProps({ selectedDate: dateRange });
  };

  showCalendar = () => {
    const date = this.state.dateDisabled,
      showCalendar = this.state.showCalendar;
    if (!date && !showCalendar) {
      console.log("show!");
      this.setState((prevState) => ({
        showCalendar: !prevState.showCalendar,
      }));
    }
  };

  render() {
    const {
      title,
      locations,
      enrollments,
      payable,
      vendors,
      selectedDate,
      onChange,
      onClick,
      errors,
    } = this.props;
    const { dateDisabled, showCalendar, startDate, endDate } = this.state;
    return (
      <div className="container-fluid">
        <div className="container pt-3 mb-5">
          <h1 className="py-3">{title}</h1>
          <div className="border-wrapper">
            <form action="" className="py-3">
              <div className="row">
                <div className="col">
                  <h3>Search/Filter</h3>
                </div>
                <div className="col">
                  <Button
                    name="Search"
                    className="btn btn-success float-right"
                    onClick={onClick}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FormSelect
                    name="inputState"
                    label="Affiliate"
                    error={errors.select}
                    options={vendors}
                    onChange={(e) => onChange(e.currentTarget.value)}
                  />
                </div>
                <div className="col ml-2">
                  <label htmlFor="">Date Range</label>
                  <div
                    className="row mt-1"
                    onChange={(event) => this.handleRadioClick(event)}
                  >
                    <FormRadio
                      name="dateRange"
                      id="dateThisMonth"
                      label="This Month"
                      defaultChecked
                      onClick={this.setDates}
                      onChange={this.handleDisabled}
                    />
                    <FormRadio
                      name="dateRange"
                      id="dateLastMonth"
                      label="Last Month"
                      onClick={this.setDates}
                      onChange={this.handleDisabled}
                    />
                    <FormRadio
                      name="dateRange"
                      id="dateCustom"
                      label="Custom"
                      onChange={this.handleDisabled}
                    />
                  </div>
                </div>
                <div className="col">
                  <label htmlFor="">Custom Range</label>
                  <div className="row">
                    <div className="col">
                      <FormInput
                        type="text"
                        className="form-control"
                        placeholder={startDate || "Start Date"}
                        readOnly={dateDisabled}
                        onClick={this.showCalendar}
                      />
                    </div>
                    <div className="col">
                      <FormInput
                        className="form-control"
                        type="text"
                        placeholder={endDate || "End Date"}
                        // value={endDate || ""}
                        readOnly={dateDisabled}
                        onClick={this.showCalendar}
                      />
                    </div>
                  </div>
                  <div className={showCalendar ? "" : "hide"}>
                    <Calendar
                      onChange={this.calendarChange}
                      value={selectedDate}
                      selectRange
                    />
                  </div>
                </div>
              </div>
              {locations && (
                <div className="row">
                  <div className="col">
                    <label htmlFor="">Enrollment Type</label>
                    <div
                      className="row mt-1"
                      onChange={(event) => this.handleRadioClick(event)}
                    >
                      <FormRadio
                        name="type"
                        id="web"
                        label="Web"
                        onClick={this.handleEnrollment}
                      />
                      <FormRadio
                        name="type"
                        id="phone"
                        label="Phone"
                        onClick={this.handleEnrollment}
                      />
                      <FormRadio
                        name="type"
                        id="both"
                        label="Both"
                        defaultChecked
                        onClick={this.handleEnrollment}
                      />
                    </div>
                  </div>
                  <div className="col">
                    <label htmlFor="">Location Type</label>
                    <div
                      className="row mt-1"
                      onChange={(event) => this.handleRadioClick(event)}
                    >
                      <FormRadio
                        name="location"
                        id="api/web"
                        label="Link Off"
                        onClick={this.handleLocation}
                      />
                      <FormRadio
                        name="location"
                        id="web"
                        label="Web"
                        onClick={this.handleLocation}
                      />
                      <FormRadio
                        name="location"
                        id="ccr"
                        label="Call Centre"
                        onClick={this.handleLocation}
                      />
                      <FormRadio
                        name="location"
                        id="all"
                        label="All"
                        onClick={this.handleLocation}
                        defaultChecked
                      />
                    </div>
                  </div>
                </div>
              )}
              {enrollments && (
                <div className="row">
                  <div className="col">
                    <label htmlFor="">Enrollment Type</label>
                    <div
                      className="row mt-1"
                      onChange={(event) => this.handleRadioClick(event)}
                    >
                      <FormRadio
                        name="type"
                        id="web"
                        label="Web"
                        onClick={this.handleEnrollment}
                      />
                      <FormRadio
                        name="type"
                        id="phone"
                        label="Phone"
                        onClick={this.handleEnrollment}
                      />
                      <FormRadio
                        name="type"
                        id="both"
                        label="Both"
                        defaultChecked
                        onClick={this.handleEnrollment}
                      />
                    </div>
                  </div>
                </div>
              )}
              
              {payable && (
                <div className="row mt-3">
                  <div className="col">
                    <FormCheck
                      name="payable"
                      id="payable"
                      label="Payable Only"
                      defaultChecked={true}
                      onClick={this.handlePayable}
                    />
                  </div>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default Search;
