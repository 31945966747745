import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default (value, resort, icon) => {
  return (
    <th
      className="sort-column"
      key={value.var}
      title={value.name}
      onClick={(e) => resort(value, icon)}
    >
      {value.name}
      <span>
        <FontAwesomeIcon icon={icon} />
      </span>
    </th>
  );
};
