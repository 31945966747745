import React, { useReducer } from "react";
import jwt from "jwt-decode";
import session from "../services/session";
import { logout } from "../services/auth";

let reducer = (user, newUser) => {
  return { ...user, ...newUser };
};

let messReducer = (message, newMessage) => {
  return { ...message, ...newMessage };
};

const initialState = getUser();
const initialStateMess = session.get("message");

const UserContext = React.createContext();
const MessageContext = React.createContext();
const AllContext = React.createContext();

// Get user data from session
function getUser() {
  try {
    // Get user info
    const user = session.get("token") || false;
    if (user) {
      let payload = jwt(user);
      // Check if expired
      let time = new Date(payload.exp);
      time < new Date() && logout();

      // console.log(payload);
      return payload.role ? payload : {};
    } else {
      return {};
    }
  } catch (error) {
    console.log(error);
  }
}

const UserProvider = (props) => {
  const [user, setUser] = useReducer(reducer, initialState);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {props.children}
    </UserContext.Provider>
  );
};

const MessageProvider = (props) => {
  const [message, setMessage] = useReducer(messReducer, initialStateMess);

  return (
    <MessageContext.Provider value={{ message, setMessage }}>
      {props.children}
    </MessageContext.Provider>
  );
};

const AllProvider = (props) => {
  const [user, setUser] = useReducer(reducer, initialState);
  const [message, setMessage] = useReducer(messReducer, initialStateMess);

  return (
    <AllContext.Provider value={{ user, setUser, message, setMessage }}>
      {props.children}
    </AllContext.Provider>
  );
};

export {
  UserContext,
  MessageContext,
  AllContext,
  AllProvider,
  UserProvider,
  MessageProvider,
};
